<template>
  <div class="app-wrapper">
    <loading v-if="loading"></loading>
    <div v-else class="good height-100">
      <!-- <div class="goback flex" @click="back">
        <i class="iconfont icon-zuojiantou"></i>
      </div> -->
      <!-- 轮播图 -->
      <van-swipe class="my-swipe img" :loop="false" indicator-color="white">
        <van-swipe-item v-for="(item, index)  in bannerList" :key="index">
          <van-image :src="item" alt="" class="img" fit="cover" />
        </van-swipe-item>
      </van-swipe>
      <div class="card">
        <div style="padding-top: 0.8rem; display: flex;flex-direction: row;justify-content: space-between;">
          <span style="color:#D23B2E; font-size: 1.2rem; font-weight: 600;">{{ selectPrice ? selectPrice :
            detail?.specifications[0]?.showPrice }}点</span>
          <span
            style="color:#D23B2E; font-size: 0.7rem; background-color: #d23c2e4e;border-radius: 0.2rem; height: 1.1rem; line-height: 1.1rem; width: 2rem;text-align: center;">包邮</span>
        </div>
        <div style="font-weight: 600; font-size: 1.05rem;margin-top: 0.6rem;">{{ detail?.productName }} </div>
        <div style="margin: 0.8rem 0;font-size: 0.8rem; color: #666; "> {{ detail?.description }}</div>
      </div>
      <div class="card">
        <div class="p-5 jcsb m-tb-10" style="font-size: 0.9rem; margin: 0.4rem 0;">
          <div class="flex" style="color: black;display: flex;flex-direction: row; "
            @click="ProductList(detail?.brandName)">
            <span style="color: #666;min-width: 5rem;">品牌</span>
            <div style=" display: flex;flex-direction: row;justify-content: space-between;flex: 1;">
              <div style=" display: flex;flex-direction: row;">{{ detail?.brandName }}</div>
              <van-icon style="margin-top: 0.15rem;margin-left: 0.5rem;" name="arrow" color="#999" size="1rem" />
            </div>
          </div>
          <div style="margin-top: 0.9rem; display: flex;flex-direction: row; " @click="showSelect">
            <span style="color: #666;min-width: 5rem;">选择</span>
            <div style=" display: flex;flex-direction: row; justify-content: space-between;flex: 1;">
              <span> {{ selectGoods }}</span>
              <div>
                <van-icon style="margin-top: 0.15rem;margin-left: 0.5rem;" name="arrow" color="#999" size="1rem" />
              </div>
            </div>
          </div>
          <!-- <div style="margin-top: 0.5rem; display: flex;flex-direction: row; justify-content: space-between;"
            @click="SelectType">
            <span style="color: #666;min-width: 2.5rem;">配送</span>
            <div style=" display: flex;flex-direction: row;"> <span> {{ sendTypeStr }}</span>
              <van-icon style="margin-top: 0.15rem;" name="arrow" />
            </div>
          </div> -->
          <div style="margin-top: 0.9rem; display: flex;flex-direction: row;" @click="SelectAddress">
            <span style="color: #666;min-width: 5rem;">{{ sendTypeTitle }}</span>
            <div style=" display: flex;flex-direction: row;justify-content: space-between;flex: 1;">
              <span> {{ address }}</span>
              <van-icon style="margin-top: 0.15rem;margin-left: 0.5rem;" name="arrow" color="#999" size="1rem" />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="context">
          <div class="icon"></div> 商品详情
        </div>
        <van-image style="width: 100%;" v-for="(item, index)  in htmEditorList" :key="index" :src="item">
        </van-image>
      </div>
      <van-goods-action>
        <!-- <van-goods-action-icon icon="chat-o" text="客服" dot /> -->
        <van-goods-action-icon icon="cart-o" text="购物车" :badge="carNum" @click="goCar" />
        <!-- <van-goods-action-icon icon="shop-o" text="店铺" /> -->
        <van-goods-action-button :disabled="canBy" type="warning" :text="carText" @click="addcars" />
        <van-goods-action-button :disabled="canBy" type="danger" :text="toaddBuy" @click="show1" />
      </van-goods-action>

      <!-- 遮罩层  立即购买-->
      <z-y-detaill-now v-if="show" @closed="closed" :good="detail" @seleceItem="seleceItem"> </z-y-detaill-now>
      <select-address v-if="showSelectAddress" :address-list="addressList" @closed="closedadd"
        @seleceAddressItem="seleceAddressItem"> </select-address>
      <send-type v-if="SelectSend" :columns="DeliveryRules" @TypeConfirm="TypeConfirm" @TypeCancel="TypeCancel"
        @closed="closeSelectSend">
      </send-type>
      <shop-address v-if="showShopAddress" :address="ShopDetail" @closed="closedshop"
        @seleceShopItem="seleceShopItem"></shop-address>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import { Toast } from "vant";
import ZYDetaillNow from "../../components/goods/ZYDetaillNow.vue";
import SelectAddress from "../../components/goods/SelectAddress.vue";
import ShopAddress from "../../components/goods/ShopAddress.vue";
import SendType from "../../components/goods/SendType.vue";

export default {
  name: "ZYDetails",
  props: {},
  data() {
    return {
      toaddBuy: '立即购买',
      carText: '加入购物车',
      canBy: false,
      loading: false,
      detail: null,
      id: this.$route.query.id,
      //商品规格
      show: false,
      username: null,
      selectGoods: '',
      sendTypeStr: '',
      sendTypeTitle: '配送地址',
      // sendTypeAddress:'',
      addressType: '',
      address: null,
      consigneeName: '',
      consigneePhone: '',
      htmEditorList: [],
      bannerList: [],
      detailform: {
        cardId: '',
        cityCode: '',
        brandId: '',
        productId: ''
      },
      addressList: [],
      ShopDetail: null,
      banner: [],
      userId: '',
      addressId: '',
      selectPrice: '',
      buyState: '',
      costPrice: '',
      showSelectAddress: false,
      goodNum: 1,
      specId: '',
      batchId: '',
      DeliveryRules: [],
      SelectSend: false,
      showShopAddress: false,
      sendSelectType: 0, //1 自提  2 快递 3 商户
    };
  },
  components: { ZYDetaillNow, SelectAddress, SendType, ShopAddress },
  methods: {
    setSendTypeTitle() {
      if (this.sendTypeStr) {
        if (this.sendTypeStr.includes('快递配送') || this.sendTypeStr.includes('商家配送')) {
          this.sendTypeTitle = '配送地址'
          this.sendSelectType = 2
          if (this.addressList.length > 0) {
            this.address = this.addressList[0].addressCname + this.addressList[0].addressQname + this.addressList[0].addressPname + this.addressList[0].address + this.addressList[0].houseNum
            this.consigneeName = this.addressList[0].recipient
            this.addressId = this.addressList[0].id
            this.consigneePhone = this.addressList[0].recipientMobile
          }
        } else if (this.sendTypeStr.includes('门店自提')) {
          this.sendTypeTitle = '商家地址'
          this.sendSelectType = 1
          if (this.ShopDetail?.shops.length > 0) {
            this.address = this.ShopDetail?.shops[0].address
          }
        }
      }
    },
    closeSelectSend(val) {
      this.SelectSend = val
    },
    TypeConfirm(value) {
      this.SelectSend = false
      if (value) {
        let val = ''
        value.forEach(element => {
          val += element + ' '
        });
        this.sendTypeStr = val
      }
      this.setSendTypeTitle()
    },
    TypeCancel() {
      this.SelectSend = false
    },
    // 获取地址列表数据
    getAddress() {
      this.$api
        .getAddress(this.userId)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          this.addressList = res.data;
          if (this.addressList) {
            this.addressList.forEach(element => {
              if (element.defaultFlag == '1') {
                this.address = element.addressCname + element.addressQname + element.addressPname + element.address + element.houseNum
                this.consigneeName = element.recipient
                this.addressId = element.id
                this.consigneePhone = element.recipientMobile
                this.addCakeAddress(element)
              }
            });
            if (!this.address) {
              this.address = this.addressList[0].addressCname + this.addressList[0].addressQname + this.addressList[0].addressPname + this.addressList[0].address + this.addressList[0].houseNum
              this.consigneeName = this.addressList[0].recipient
              this.addressId = this.addressList[0].id
              this.consigneePhone = this.addressList[0].recipientMobile
              this.addCakeAddress(this.addressList[0])
            }
          }
          if (!this.address) {
            this.address = '暂无收货地址点击添加地址'
          }
        })
        .catch();
    },
    SelectType() {
      this.SelectSend = true
    },
    ProductList(val) {
      this.$router.push({
        path: "/ProductList",
        query: {
          brandName: val,
          category1Id: this.detail?.category1Id,
          category2Id: this.detail?.category2Id,
        },
      });
    },
    SelectAddress() {
      if (this.sendSelectType == 1) {
        if (this.ShopDetail) {
          this.showShopAddress = true
        }
      } else {
        if (this.addressList)
          this.showSelectAddress = true
        else {
          this.$router.push("/EditAddress");
        }
      }

    },
    showSelect() {
      this.show = true
    },
    // 获取商品详情
    CakeInfoDetail(id) {
      this.$api
        .queryAutarkyCakeInfoDetail(id)
        .then((res) => {
          if (res.code === 0) {
            this.detail = res.data
            try {
              if (res.data.specifications.length > 0) {
                res.data.specifications.forEach(element => {
                  if (element.status != '3') {
                    this.selectGoods = element.specifications + " x 1"
                    this.selectPrice = element.showPrice
                    this.buyState = element.status
                    this.costPrice = element.costPrice
                    this.specId = element.id
                    this.batchId = element.batchId
                    this.htmEditorList = element.htmEditorList
                    this.bannerList = element.bannerList
                    throw new Error("已找到，退出循环");
                  }
                });
              } else {
                this.selectGoods = '暂无商品'
                this.selectPrice = '暂无价格'
                this.buyState = '3'
                this.costPrice = ''
                this.specId = ''
                this.batchId = ''
                this.htmEditorList.length = 0
                this.bannerList.length = 0
              }
            } catch (error) {
              if (error.message == 'false') {
                return
              }
            }


            this.getAddress();
          } else {
            Toast.loading({
              message: "加载失败",
              forbidClick: true,
              loadingType: "spinner",
              duration: "1000",
            });
          }

        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 去购物车
    goCar() {
      this.$router.push({
        path: "/myCart",
        query: {
          type: 1,
        },
      });
    },
    //   返回
    back() {
      this.$router.push("/");
    },
    // 图片预览
    toView(val) {
      ImagePreview({
        images: this.good.images,
        closeable: true,
        startPosition: val,
      });
    },
    // next的方法
    addcars() {
      if (!this.address) {
        this.addressTag()
        return
      }
      Dialog.confirm({
        title: "加入购物车",
        message: "确认将该商品加入购物车",
      }).then(() => {
        this.$api
          .addShop({
            addressId: this.addressId,
            userId: this.userId,
            sourceChannel: "购物车",
            batchId: this.batchId,
            productId: this.detail?.id,
            skuId: this.specId,
            productNum: this.goodNum,
            productPrice: this.selectPrice,
            productTotal: parseFloat(this.selectPrice) * parseFloat(this.goodNum),
            productType: this.detail?.type,
            shipType: 'same',
            status: "0",
            supplierId: this.detail?.supplierId,
            productName: this.detail?.productName,
            coverImage: this.detail?.specifications[0].coverImage,
            consigneeAddress: this.address,
            consigneeName: this.consigneeName,
            consigneePhone: this.consigneePhone,
            costPrice: this.costPrice,
            cardId: this.detailform.cardId,
            type: '自营',
            cityCode: this.detailform.cityCode,
            category1Id: this.detail?.category1Id,
            category1Name: this.detail?.category1Name,
            category2Id: this.detail?.category2Id,
            category2Name: this.detail?.category2Name,
          })
          .then((res) => {
            if (res.code == 1) {
              Toast(res.msg);
              return
            }
            Toast.success("加入购物车成功");
            // 请求购物车数据
            // this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      }).catch(() => {
        // on cancel
      });
    },
    addressTag() {
      Dialog.confirm({
        title: "提示",
        message: "请先设置收货地址",
        confirmButtonText: '去设置'
      }).then(() => {
        this.$router.push("/EditAddress");
      }).catch(() => {
        // on cancel
      });
    },

    // 立即购买
    show1() {
      if (!this.address) {
        this.addressTag()
        return
      }
      let item = {

        costPrice: this.costPrice,
        emptionNum: this.goodNum,
        productId: this.detail?.id,
        productName: this.detail?.productName,
        productType: this.detail?.type,
        shipType: 'same',
        showPrice: this.selectPrice,
        batchId: this.batchId,
        skuId: this.specId,
        supplierId: this.detail?.supplierId,
        category1Id: this.detail?.category1Id,
        category1Name: this.detail?.category1Name,
        category2Id: this.detail?.category2Id,
        category2Name: this.detail?.category2Name,
      }
      this.$api
        .productOrderInfo({
          addressId: this.addressId,
          productSubOrderList: [item],
          userId: this.userId,
          cardId: this.detailform.cardId,
          cityCode: this.detailform.cityCode,
          productNum: this.goodNum,
          productPrice: this.selectPrice,
          productTotal: parseFloat(this.selectPrice) * parseFloat(this.goodNum),
          payProductTotal: parseFloat(this.selectPrice) * parseFloat(this.goodNum),
          status: "0",
          costPrice: this.costPrice,
          coverImage: this.detail?.specifications[0].coverImage,
          consigneeAddress: this.address,
          consigneeName: this.consigneeName,
          consigneePhone: this.consigneePhone,
        })
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg)
            return
          }
          localStorage.setItem('gooddetail', JSON.stringify(res.data))
          this.$router.push({
            path: "/Cashier",
            query: {
              type: 'zy-shop',
              creatTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 关闭弹窗
    closed(val) {
      this.show = val;
    },
    closedadd(val) {
      this.showSelectAddress = val
    },
    closedshop(val) {
      this.showShopAddress = val
    },
    seleceItem(val) {
      this.goodNum = val.cont
      this.batchId = this.detail.specifications.length > val.index ? this.detail.specifications[val.index].batchId : ''
      this.specId = this.detail.specifications.length > val.index ? this.detail.specifications[val.index].id : ''
      this.selectGoods = this.detail.specifications.length > val.index ? this.detail.specifications[val.index].specifications + " x " + val.cont : '暂无商品'
      this.selectPrice = this.detail.specifications.length > val.index ? this.detail.specifications[val.index].showPrice : '暂无价格'
      this.buyState = this.detail.specifications.length > val.index ? this.detail.specifications[val.index].status : '3'
      this.htmEditorList = this.detail.specifications.length > val.index ? this.detail.specifications[val.index].htmEditorList : ''
      this.bannerList = this.detail.specifications.length > val.index ? this.detail.specifications[val.index].bannerList : ''
      if (this.buyState == '3') {
        this.toaddBuy = '补货中'
        this.carText = '补货中'
        this.canBy = true
      } else {
        this.toaddBuy = '立即购买'
        this.carText = '加入购物车'
        this.canBy = false
      }
      this.closed(false)
    },
    seleceAddressItem(item) {
      //item.addressCname}}{{item.addressQname}}{{ item.addressPname
      this.address = item.addressCname + item.addressQname + item.addressPname + item.address + item.houseNum
      this.consigneeName = item.recipient
      this.addressId = item.id
      this.consigneePhone = item.recipientMobile
      this.addCakeAddress(item)

      this.showSelectAddress = false
    },
    seleceShopItem(val) {
      this.address = val
      this.showShopAddress = false
    },
    canSend() {
      let form = {
        addressId: this.addressId,
        userId: this.userId,
        batchId: this.batchId,
        productId: this.detail?.id,
        skuId: this.specId,
        productNum: this.goodNum,
        productPrice: this.selectPrice,
        productTotal: parseFloat(this.selectPrice) * parseFloat(this.goodNum),
        productType: this.detail?.type,
        shipType: 'same',
        status: "0",
        supplierId: this.detail?.supplierId,
        productName: this.detail?.productName,
        coverImage: this.detail?.specifications[0].coverImage,
        consigneeAddress: this.address,
        consigneeName: this.consigneeName,
        consigneePhone: this.consigneePhone,
        costPrice: this.costPrice,
        cardId: this.detailform.cardId,
        type: '自营',
        cityCode: this.detailform.cityCode,
        category1Id: this.detail?.category1Id,
        category1Name: this.detail?.category1Name,
        category2Id: this.detail?.category2Id,
        category2Name: this.detail?.category2Name,
      }
      this.$api.checkSelfSupIsDelivery(form).then((res) => {
        let orderInfo = res.data;
        if (orderInfo.productSubOrderList[0].deliveryFlag) {
          this.toaddBuy = '立即购买'
          this.canBy = false
          if (this.buyState == '3') {
            this.toaddBuy = '补货中'
            this.carText = '补货中'
            this.canBy = true
          } else {
            this.toaddBuy = '立即购买'
            this.carText = '加入购物车'
            this.canBy = false
          }
        } else {
          this.toaddBuy = '该地址不可配送'
          this.canBy = true
        }
      })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 添加地址
    addCakeAddress(item) {
      this.canSend()
    },

  },
  mounted() {
    this.userId = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")).id
      : "";
    this.detailform.productId = this.$route.query.id;
    this.detailform.cityCode = localStorage.getItem("cityCode");
    this.detailform.cardId = localStorage.getItem("cardId");
    this.CakeInfoDetail(this.detailform.productId);

  },

  activated() {
    const scrollTop = this.$route.meta.scrollTop;
    const $wrapper = document.querySelector('.app-wrapper');
    if (scrollTop && $wrapper) {
      $wrapper.scrollTop = scrollTop;
    }
    // this.CakeInfoDetail(this.detailform.productId);
  },
  computed: {
    // 购物车的角标
    carNum() {
      if (this.$store.state.nickname) {
        if (this.$store.state.carNum > 0) {
          return this.$store.state.carNum;
        } else {
          return;
        }
      } else {
        return;
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>